<template>
<div class="terms-view white-bg">
    <div class="terms-top-view pt-200">
        <h1 class="top-h1" data-aos="fade-right">Умови користування</h1>
        <p class="top-p mt-20px" data-aos="fade-left">Корисна інформація для Вас</p>
        <div class="terms-top-bg" >
            <img src="@/assets/img/stockbg.svg" alt="">
        </div>
    </div>
    <div class="flex justify-center">
        <div class="terms mw-1920  ">
            <div class="terms-item" v-for="i in 4" :key="i" >
                <h1 data-aos="fade-right">{{i}}. Пункт</h1>
                <p data-aos="fade-left">
                    Amet vel ipsum porttitor vel sed in nunc, id. Phasellus feugiat semper sit mauris tempor, risus in. Sed mauris nullam vestibulum dui molestie vivamus. Quis maecenas suspendisse massa sed. Ipsum neque lacus varius tempus viverra enim viverra nunc. Ut scelerisque aliquet quisque sit massa lacus varius.
                    Pulvinar commodo, et enim, diam sed vel sit orci. Neque, purus tellus tempor, in hac amet. Odio leo, nisl enim purus platea ultricies viverra eget nullam. Felis velit nisi vitae, orci. Interdum fames feugiat nunc, vestibulum nulla accumsan donec. Sed adipiscing tristique convallis volutpat purus eget sed arcu viverra. Feugiat habitasse rhoncus nullam nisl eu at ac. Vulputate eleifend lectus bibendum ipsum nam ut. Consequat bibendum eu diam tortor, faucibus accumsan lacus auctor. Eget sollicitudin dignissim dui tellus urna. A est mauris pellentesque quis feugiat integer accumsan platea integer.
                    Arcu volutpat senectus etiam leo. Morbi malesuada ultrices dolor sit. Sem elit facilisi quam sem. Consectetur sed tristique lectus amet, morbi tellus tincidunt. Tortor proin netus et nam molestie ut id.
                    Volutpat quam ac ultrices sed massa tincidunt libero. Cras nullam accumsan neque ultricies in dictum nisl arcu. Pharetra aliquet ultrices neque proin aliquet felis tellus. Est tortor nulla imperdiet consequat. Quis nam quam aliquam nisi, proin semper odio molestie. Est vel neque varius id habitant. Aliquam ultrices dignissim ac est feugiat scelerisque risus. Vivamus dictumst libero adipiscing dictumst aliquam placerat posuere non enim. At est sed dictum quis lectus morbi elit. Pharetra tortor lectus placerat in sem facilisi quis ultrices. Risus tortor, et, nisi, mauris massa commodo gravida ligula. Odio vitae est sapien, lacus, suspendisse senectus risus nec rhoncus. Pretium, facilisis blandit egestas dui, auctor pharetra.
                    Lectus eget pulvinar enim faucibus et volutpat. Vulputate adipiscing sed justo in non. Ridiculus at mattis semper tortor enim morbi euismod platea. Lorem in imperdiet neque, commodo eleifend massa leo risus. Sed aenean enim tincidunt sagittis. Auctor luctus elit diam euismod gravida odio sed est. Odio non libero.
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
  export default {
      name: 'terms',

  }
</script>

<style lang="scss">
.terms {
    width: 100%;
    padding: 30px 120px;

}

.terms-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top);
    padding: 100px 120px;
    position: relative;
}

.stock-mover {
    transform: translateY(-100px);
}

.terms-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 30%;
    }
}
.terms-item{
    padding: 30px 0px;
    h1{
        font-family: Stem-Medium;
        font-size: 30px;
        line-height: 35px; 
        color: var(--other-black);

    }
    p{
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 24px;
        /* or 150% */

        text-indent: 20px;

        color: var(--other-black);
        margin-top: 20px;
    }
}

@media screen and(max-width:1100px) {
    .terms-top-view {
        padding: 100px 20px;
    }

    .terms {
        padding: 30px 20px;
    }
}

@media screen and(max-width:950px) {
    .terms-top-view {
        padding: 100px 20px;
    }

}

@media screen and(max-width:768px) {
    .terms-top-view {
        padding: 60px 20px;
    }

    .terms-top-bg {
        display: none;
    }
    .terms-item{
    padding: 20px 0px;
    h1{
        font-size: 20px;
        line-height: 1.5rem;

    }
    p{
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}
}
</style>
